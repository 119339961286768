<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card
      class="rounded-xl fill-height overflow-hidden"
      :loading="loading"
      :disabled="loading"
    >
      <v-expand-transition>
        <div v-if="details" class="d-flex flex-column">
          <v-card-title
            class="headline primary white--text d-flex align-center py-2"
          >
            <v-layout column>
              <div class="text-body-1 font-weight-bold">
                {{ details.fullname }}
              </div>
              <div class="d-flex flex-row align-center">
                <div class="text-caption font-weight-bold mr-3">
                  ID: {{ details.id }}
                </div>
                <div class="text-caption font-weight-bold">
                  Surgery ID: SU-{{ details.id }}
                </div>
              </div>
            </v-layout>

            <v-spacer />
            <btn-update-helft-care :patient="details" />
            <v-divider class="mx-4" vertical />
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="white"
                    v-on="on"
                    icon
                    :disabled="dialogmakeCall"
                    @click="openCall()"
                  >
                    <v-icon>mdi-phone-dial</v-icon>
                  </v-btn>
                </template>
                <span>Make Call</span>
              </v-tooltip>

              <!--    <btn-make-call
              :external="true"
              :numberPatient="details.homePhone"
            /> -->
            </template>

            <!--  <drawer-sms :details="details" :origin="'patient'" /> -->
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="white"
                  :disabled="showSmsGlobal"
                  icon
                  @click="openSMS"
                  ><v-icon>mdi-android-messages</v-icon></v-btn
                >
              </template>
              <span>Send Message</span>
            </v-tooltip>

            <send-email :user="details" />
            <v-divider class="mx-4" vertical />
            {{/* Update patient */}}
            <v-tooltip transition="fade-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  :to="'/patients/update/' + $route.params.uuid"
                  dark
                  icon
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Update {{ getpatientN }}</span>
            </v-tooltip>
            {{/* Update patient */}}
            <v-tooltip transition="fade-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  :loading="loading"
                  dark
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="getPatientData"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Update state of {{ getpatientN }}
            </v-tooltip>
            <template v-if="isAdmin || isSuper">
              <btn-remove-patient :patient="details" />
            </template>
          </v-card-title>

          <v-toolbar dense height="80">
            <btn-add-appointment
              :patient="details"
              :btnProps="{ color: 'primary' }"
            />

            <!--  <btn-create-procedure
              :internal="true"
              :patientUuid="details.uuid"
              @created="getPatientData"
            /> -->
            <btn-create-quote @created="getPatientData" />
            <v-divider class="mx-1" vertical />
            <btn-procedures
              :procedures="procedures"
              @selected="getPatientData"
              @update-patient="getPatientData"
            />
            <div
              v-if="procedureData"
              class="text-body-1 ml-2 my-4 d-flex flex-column"
            >
              <div>{{ procedureData.name }}</div>
              <div class="text-caption d-flex">
                ID: <b class="mx-1">{{ procedureData.id }}</b>
                Created at:
                <b class="mx-2">{{ procedureData.date }}</b>
                <div
                  v-if="procedureData.treatmentType != null"
                  class="text-caption mx-2"
                >
                  Total: <b>{{ procedureData.totalTreatment | currency }}</b>
                </div>
                <div v-else class="text-caption mx-2">
                  Total: <b>{{ procedureData.total | currency }}</b>
                </div>
                <div class="text-caption">
                  Amount due: <b>{{ procedureData.deb | currency }}</b>
                </div>
                <template>
                  <div class="text-caption mx-2 d-flex surgi">
                    <div>
                      {{ typeProc }} PreOp Date:
                      <b class="mr-2"
                        >{{
                          procedureData.preopDate != null
                            ? prettyDate(procedureData.preopDate)
                            : "-"
                        }}
                      </b>
                    </div>
                    <div>
                      {{ typeProc }} Date:
                      <b
                        >{{
                          procedureData.surgicalDate != null
                            ? prettyDate(procedureData.surgicalDate)
                            : "-"
                        }}
                      </b>
                    </div>

                    <div class="editsurgi">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            icon
                            small
                            color="error"
                            @click="toedit()"
                          >
                            <v-icon small>mdi-pencil</v-icon></v-btn
                          >
                        </template>
                        <span>Edit {{ typeProc }} Date</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <v-spacer />
            <btn-procedure-status
              v-if="procedure != undefined && procedure != null"
              :procedure="procedure"
              @updated="getPatientData"
              :btnProps="{
                color: 'white',
                class: 'primary',
                rounded: true,
              }"
            />
          </v-toolbar>
          <v-divider />
        </div>
      </v-expand-transition>
      <template v-if="dialogEditProcedure">
        <v-dialog persistent v-model="dialogEditProcedure" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Edit {{ typeProc }} Date
            </v-card-title>

            <v-card-text class="mt-2">
              <div class="d-flex">
                <div class="col-sm-6">
                  <ma-date-picker
                    editable
                    :datepickerProps="{
                      'no-title': true,
                    }"
                    v-model="req.preopDate"
                    :label="typeProc + ' PreOp Date'"
                  />
                </div>
                <template v-if="showPreopDate != ''">
                  <div class="col-sm-6">
                    <v-text-field
                      name="name"
                      :label="'Current ' + typeProc + ' Preop Date'"
                      outlined
                      dense
                      disabled
                      v-model="showPreopDate"
                    ></v-text-field>
                  </div>
                </template>
              </div>
              <div class="d-flex">
                <div class="col-sm-6">
                  <ma-date-picker
                    editable
                    :datepickerProps="{
                      'no-title': true,
                    }"
                    v-model="req.surgicalDate"
                    :label="typeProc + ' Date'"
                  />
                </div>
                <template v-if="showSurgicalDate">
                  <div class="col-sm-6">
                    <v-text-field
                      name="name"
                      :label="'Current ' + typeProc + ' Date'"
                      outlined
                      dense
                      disabled
                      v-model="showSurgicalDate"
                    ></v-text-field>
                  </div>
                </template>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="cancelEditProc"> Cancel </v-btn>
              <v-btn
                :loading="loadingEditPro"
                :disabled="
                  loadingEditPro ||
                  (req.surgicalDate == '' && req.preopDate == '')
                "
                color="primary"
                text
                @click="confirmEditProc"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <v-tabs v-model="tabSelected" grow show-arrows>
        <v-tab v-for="(tab, i) in tabs" :key="i" :href="tab.href">
          <v-icon left>{{ tab.icon }}</v-icon>
          {{ tab.title }}
          <template v-if="tab.title == 'Document Signing' && unreadDoc">
            <span
              class="text-center"
              style="
                background-color: orange;
                width: 30px;
                border-radius: 25px;
                margin: 0 5px;
                color: #ffff;
              "
              >{{ countUnread }}</span
            >
          </template>
        </v-tab>

        {{/* Check list */}}

        <v-tab-item
          value="checklist"
          v-if="procedure != undefined && procedure != null && !getviewMHistory"
        >
          <check-list
            :procedureType="procedureType"
            :procedure="procedure"
            @update-patient="getPatientData"
          />
        </v-tab-item>

        {{/* Patient Details */}}
        <v-tab-item value="details" v-if="details != null">
          <patient-personal-details :patient="details" />
        </v-tab-item>
        <v-tab-item value="relations" v-if="details != null">
          <relations />
        </v-tab-item>
        {{/* Patient Labs */}}
        <template v-if="getviewLabs">
          <v-tab-item value="labs" v-if="details != null">
            <patient-labs @updatelabs="getPatientData" :patient="details" />
          </v-tab-item>
        </template>
        {{/* Patient Medical History */}}
        <template v-if="getviewMHistory">
          <v-tab-item value="mhistory" v-if="details != null">
            <patient-medical-history />
          </v-tab-item>
        </template>

        <!--  Quotes -->
        <v-tab-item value="quotes" v-if="details != null">
          <quotes />
        </v-tab-item>
        <!--  Accessories -->
        <v-tab-item value="accessories" v-if="details != null">
          <accessories-sales />
        </v-tab-item>

        {{/* Paymets */}}
        <v-tab-item
          value="payments"
          v-if="procedure != undefined && procedure != null"
        >
          <payments :procedure="procedure" @update-patient="getPatientData" />
        </v-tab-item>
        {{/* Notes */}}
        <v-tab-item value="notes" v-if="details != null">
          <notes :patient="details" @update-patient="getPatientData" />
        </v-tab-item>
        {{/* Document Signing */}}
        <template>
          <v-tab-item
            value="documents"
            v-if="procedure != undefined && procedure != null"
          >
            <document-signing
              :patient="procedure"
              @update-patient="getPatientData"
            />
          </v-tab-item>
        </template>
      </v-tabs>
      <v-dialog
        v-model="dialogConfirmWhy"
        max-width="400"
        persistent
        content-class="rounded-xl"
      >
        <v-card>
          <v-card-title class="text-h6 white--text green lighten-2">
            {{ $t("dialogTitle") }}
          </v-card-title>
          <v-card-text>
            <v-layout class="pa-1" column>
              <v-textarea
                outlined
                name="input-7-4"
                label="Reasons"
                v-model="reasonswhy"
              ></v-textarea>
            </v-layout>
          </v-card-text>

          <v-divider />
          <v-card-actions class="justify-end">
            <v-btn color="error" :disabled="loading" @click="cancelEntry" text>
              {{ $t("labelCancel") }}
            </v-btn>
            <v-btn
              color="success"
              :loading="loadingwhy"
              :disabled="
                reasonswhy == '' ||
                reasonswhy == null ||
                reasonswhy == undefined ||
                loadingwhy
              "
              @click="_goToPatient"
              text
            >
              {{ $t("labelAccept") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { getAPI } from "@/api/axios-base";
import { isDevelop } from "@/enviorment";
import BtnUpdateHelftCare from "./buttons/BtnUpdateHelftCare.vue";
/* import BtnCreateProcedure from "./buttons/BtnCreateProcedure.vue"; */
import BtnProcedures from "./buttons/BtnProcedures.vue";
import BtnCreateQuote from "@/views/Patients/buttons/BtnCreateQuote.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { notifyError } from "@/components/Notification";
import AccessoriesSales from "@/views/Patients/AccessoriesSales.vue";
import SendEmail from "@/components/emails/SendEmail.vue";
import PatientLabs from "@/views/Patients/PatientLabs.vue";
import PatientMedicalHistory from "@/views/Patients/PatientMedicalHistory.vue";
const BtnAddAppointment = () =>
  import(
    /* webpackChunkName: "add-appointment" */ "@/views/Appointments/buttons/BtnAddAppointment.vue"
  );
const BtnRemovePatient = () =>
  import(
    /* webpackChunkName: "remove-patient" */ "./buttons/BtnRemovePatient.vue"
  );
const Notes = () => import(/* webpackChunkName: "notes" */ "./Notes/Notes.vue");
const Relations = () =>
  import(/* webpackChunkName: "relations" */ "@/views/Patients/Relations.vue");
const CheckList = () =>
  import(/* webpackChunkName: "check-list" */ "./CheckList.vue");
const Payments = () =>
  import(/* webpackChunkName: "payments" */ "./Payments.vue");
const Quotes = () => import(/* webpackChunkName: "quotes" */ "./Quotes.vue");
const PatientPersonalDetails = () =>
  import(
    /* webpackChunkName: "patient-personal-details" */ "./PatientPersonalDetails.vue"
  );
const DocumentSigning = () =>
  import(/* webpackChunkName: "document-signing" */ "./DocumentSigning.vue");
const BtnProcedureStatus = () =>
  import(
    /* webpackChunkName: "procedure-status" */ "@/views/Patients/buttons/BtnProcedureStatus.vue"
  );

export default Vue.extend({
  name: "patient-details",
  components: {
    CheckList,
    Notes,
    Quotes,
    DrawerSms,
    Payments,
    PatientPersonalDetails,
    BtnRemovePatient,
    DocumentSigning,
    Relations,
    // BtnResetSteps,
    BtnCreateQuote,
    BtnMakeCall,
    BtnUpdateHelftCare,
    /*  BtnCreateProcedure, */
    BtnProcedures,
    BtnAddAppointment,
    BtnProcedureStatus,
    AccessoriesSales,
    SendEmail,
    PatientLabs,
    PatientMedicalHistory,
    MaDatePicker,
  },
  data() {
    return {
      details: null,
      loadingEditPro: false,
      dialogEditProcedure: false,
      req: {
        surgicalDate: "",
        preopDate: "",
      },

      // The procedure selected

      dialogConfirmWhy: false,
      reasonswhy: "",
      loadingwhy: false,

      loading: false,
      tabSelected: 0,
      dialogSignStep1: false,
      dialogDocument: false,
      isDevelop: isDevelop,
      procedureName: "",
      procedureType: "",
      procedureCreatedAt: "",
      procedureData: null,
      sms: {
        number: "",
        message: "",
        // replyTo: "+17869461441",
      },

      dialogImgShow: false,
      image: "",
      width: 800,
    };
  },

  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "getpatientN",
      "getviewLabs",
      "getviewMHistory",
      "gettabPatientDetails",
    ]),
    ...mapState("crmDashboardModule", ["procedureSelectid"]),
    ...mapState("crmMedicFormModule", [
      "procedure",
      "patientDetail",
      "unreadDoc",
      "countUnread",
    ]),
    ...mapState(["showSmsGlobal"]),
    ...mapState("crmSMSModule", ["messagesPatient"]),
    ...mapState("crmCallCenterModule", ["dialogmakeCall"]),
    showPreopDate() {
      if (this.procedureData == null) {
        return "";
      }
      if (this.procedureData.preopDate == null) {
        return "";
      }
      return moment(this.procedureData.preopDate).format("MM/DD/YYYY");
    },
    showSurgicalDate() {
      if (this.procedureData == null) {
        return "";
      }
      if (this.procedureData.surgicalDate == null) {
        return "";
      }
      return moment(this.procedureData.surgicalDate).format("MM/DD/YYYY");
    },

    procedures() {
      return this.details.proceduresEntity.map((procedure) => {
        let payments = 0.0;
        const paids = procedure.payments;
        paids.forEach((pay) => {
          const p = pay.status == "CONFIRMED" ? parseFloat(pay.amount) : 0.0;
          payments = payments + p;
        });
        const total = procedure.totalTreatment;
        /* .reduce(
            (total: number, item: { amount: string }) => {
              return total + parseFloat(item.amount);
            },
            0
          ); */
        const date = moment(procedure.createAt).format("MM-DD-YYYY");
        const status = procedure.activeStatus;
        let doctor = "Not defined";

        if (procedure.doctor != null) {
          doctor = procedure.doctor.fullname;
        }

        if (
          procedure.treatmentType &&
          procedure.treatmentType != null &&
          procedure.treatmentType !== undefined
        ) {
          return {
            name: procedure.treatmentType.name,
            id: procedure.id,
            type: procedure.procedure_type,
            surgicalDate: procedure.surgicalDate,
            preopDate: procedure.preopDate,
            payments: procedure.payments,
            date: date,
            total: total,
            deb: total - payments,
            dasboardStatus: procedure.dasboardStatus,
            doctor: doctor,
            status: status,
          };
        }

        // let deposit = 0;

        if (
          procedure.surgeryType &&
          procedure.surgeryType != null &&
          procedure.surgeryType !== undefined
        ) {
          return {
            name: procedure.surgeryType.name,
            id: procedure.id,
            type: procedure.procedure_type,
            date: date,
            total: total,
            surgicalDate: procedure.surgicalDate,
            preopDate: procedure.preopDate,
            deb: total - payments,
            dasboardStatus: procedure.dasboardStatus,
            doctor: doctor,
            status: status,
          };
        }
      });
    },

    tabs() {
      let tabs = this.gettabPatientDetails;
      if (
        this.details !== undefined &&
        this.details !== null &&
        this.procedure !== undefined &&
        this.procedure !== null
      ) {
        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        } else {
          tabs = tabs.filter((t) => t.title != "Check list");
        }
      } else {
        if (!this.getviewLabs) {
          tabs = tabs.filter((t) => t.title != "Labs");
        }
        if (!this.getviewMHistory) {
          tabs = tabs.filter((t) => t.title != "Medical History");
        }
        tabs = tabs.filter(
          (t) =>
            t.title != "Check list" &&
            t.title != "Payments" &&
            t.title != "Document Signing"
        );
      }

      return tabs;
    },
    typeProc() {
      if (this.procedure == null) {
        return "-";
      }
      if (this.procedure.treatmentType != null) {
        return "Treatment";
      }
      return "Surgery";
    },
  },
  async mounted() {
    localStorage.setItem("filterfrompatients", true);
    if (this.isSuper || this.isAdmin || !this.getRequireNote) {
      await this.getPatientData();
    } else {
      this.dialogConfirmWhy = true;
    }
  },
  destroyed() {
    this.mutprocedureSelectid(0);
    this.mutProcedure(null);
  },
  methods: {
    ...mapMutations("crmDashboardModule", ["mutprocedureSelectid"]),
    ...mapMutations("crmMedicFormModule", [
      "mutPatientDetails",
      "mutProcedure",
      "mutPatientQuotes",
      "mutunreadDoc",
      "mutcountUnred",
    ]),
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapActions("crmSMSModule", ["actMessagesPerNumber", "actSendMessage"]),
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    ...mapMutations(["mutShowSms"]),

    openCall() {
      localStorage.setItem("number", this.patientDetail.homePhone);
      this.mutDialogmakeCall(true);
    },
    openSMS() {
      localStorage.setItem("numberSMS", this.patientDetail.homePhone);
      localStorage.setItem("nameSMS", this.patientDetail.fullname);
      this.mutShowSms(true);
    },

    toedit() {
      this.dialogEditProcedure = true;
    },
    prettyDate(date) {
      return moment(date).format("MM/DD/YY");
    },

    async _goToPatient() {
      try {
        this.loadingwhy = true;
        const patient = (
          await getAPI.post("/patient/getPatientWithNotes", {
            patientUuid: this.$route.params.uuid,
            note: this.reasonswhy,
          })
        ).data;
        this.loadingwhy = false;
        this.reasonswhy = "";
        this.dialogConfirmWhy = false;
        this.mutPatientDetails(patient);
        this.mutPatientQuotes(patient);
        this.loading = false;
        this.details = patient;
        // Si ya había un procedimiento seleccionado se actualiza con éste
        if (this.procedure == null && this.procedureSelectid != 0) {
          await this.getProcedureData(this.procedureSelectid);
        } else {
          if (this.procedure == null) {
            return;
          }

          await this.getProcedureData(
            this.procedure != null ? this.procedure.id : 0
          );
        }
      } catch (error) {
        this.loadingwhy = false;
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },
    cancelEntry() {
      this.dialogConfirmWhy = false;
      this.reasonswhy = "";
      this.$router.push("/patients");
    },

    async getPatientData() {
      this.loading = true;

      const patient = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      this.mutPatientDetails(patient);
      this.mutPatientQuotes(patient.quotes);
      this.loading = false;
      this.details = patient;
      // Si ya había un procedimiento seleccionado se actualiza con éste
      if (this.procedure == null && this.procedureSelectid != 0) {
        await this.getProcedureData(this.procedureSelectid);
      } else {
        if (this.procedure == null) {
          return;
        }

        await this.getProcedureData(
          this.procedure != null ? this.procedure.id : 0
        );
      }
    },
    async getProcedureData(procedureId) {
      this.loading = true;
      const pi = this.procedure != null ? this.procedure.id : procedureId;

      const procedure = this.procedures.find((item) => item.id == pi);

      if (procedure.status != "CANCELED") {
        this.procedureData = procedure != undefined ? procedure : null;
      }
      const responseData = (await getAPI(`/patient/getProcedure/${pi}`)).data;

      if (procedure.status != "CANCELED") {
        this.procedureType =
          procedure != undefined ? procedure.type : "Treatment";
      }
      if (responseData.activeStatus != "CANCELED") {
        this.mutProcedure(responseData);
      } else {
        this.mutProcedure(null);
      }
      let c = 0;
      if (responseData.envelope.length != 0) {
        responseData.envelope.forEach((r) => {
          if (!r.read && r.lastDocusStatus == "completed") {
            c = c + r.documents.length;
          }
        });
      }
      if (this.details.envelopes.length != 0) {
        this.details.envelopes.forEach((r) => {
          if (!r.read && r.lastDocusStatus == "completed") {
            c = c + r.documents.length;
          }
        });
      }
      this.mutcountUnred(c);
      if (c != 0) {
        this.mutunreadDoc(true);
      }

      this.loading = false;
    },

    cancelEditProc() {
      this.dialogEditProcedure = false;
      this.req = { surgicalDate: "", preopDate: "" };
      this.loadingEditPro = false;
    },
    confirmEditProc() {
      this.loadingEditPro = true;
      const body = Object.assign({}, this.req);

      if (body.surgicalDate != "" && body.surgicalDate != undefined) {
        body.surgicalDate = moment(body.surgicalDate).toISOString();
      } else {
        delete body.surgicalDate;
      }
      if (body.preopDate != "" && body.preopDate != undefined) {
        body.preopDate = moment(body.preopDate).toISOString();
      } else {
        delete body.preopDate;
      }

      getAPI
        .post("/sprocedures/updateProcedure/" + this.procedure.id, body)
        .then(() => {
          this.getPatientData();
          this.cancelEditProc();
        })
        .catch((error) => {
          this.loadingEditPro = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.surgi {
  position: relative;
}
.editsurgi {
  position: absolute;
  right: -30px;
  top: -5px;
}
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);

  main {
    padding: 10px;
    height: 75vh;
    margin: 10vh 0 10vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: #1e1e24;
    }

    &::-webkit-scrollbar-thumb {
      background: #6649b8;
    }
  }

  form {
    height: 5vh;
    position: fixed;
    bottom: 0;
    background-color: rgb(24, 23, 23);
    width: 100%;
    max-width: 728px;
    display: flex;
    font-size: 1.5rem;

    button {
      width: 20%;
      background-color: rgb(56, 56, 143);
    }

    input {
      line-height: 1.5;
      width: 100%;
      font-size: 1.5rem;
      background: rgb(58, 58, 58);
      color: white;
      outline: none;
      border: none;
      padding: 0 10px;
    }
  }

  .message {
    display: flex;
    align-items: center;

    &.received {
      p {
        background: #e5e5ea;
        color: #000;
      }
    }

    &.sent {
      flex-direction: row-reverse;

      p {
        color: #fff;
        background: #0b93f6;
        align-self: flex-end;
      }
    }

    .img {
      width: 100px;
      height: 80px;
      max-height: 80px;
      max-width: 100px;
      margin: 2px 5px;
    }

    p {
      max-width: 500px;
      margin-bottom: 12px;
      line-height: 24px;
      padding: 10px 20px;
      border-radius: 25px;
      position: relative;
      color: #fff;
      text-align: justify;
    }
  }

  button {
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
    border-radius: 5%;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  button,
  input {
    color: #fff;
    border: none;
  }

  p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
  }
}
</style>
<i18n>
{
  "en": {   
    "dialogTitle": "Reason why you open details",
    "notification": "Successfully removed" 
  },
  "es": {    
    "dialogTitle": "Razon por la que abre los detalles ",
    "notification": "Eliminado con éxito"
}}
</i18n>
